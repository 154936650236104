.techs {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  background-color: #272727;
  margin: 0px auto;
  padding: 100px 70px;
}

@media screen and (max-width: 769px) {
  .techs {
    padding: 90px 50px;
    margin: 0px;
  }
}
@media screen and (max-width: 430px) {
  .techs {
    padding: 70px 18px;
    background: #202020;
  }
}

.techs__container {
  display: flex;
  flex-direction: column;
}

/* @media screen and (max-width: 769px) {
  .techs__container {
  }
} */
@media screen and (max-width: 430px) {
  .techs__container {
    margin: 0px;
  }
}

.techs__title {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  text-align: left;
  margin: 0px;
  padding: 0px;
  padding-bottom: 23px;
  border: none;
  border-bottom: 1px solid #dadada;
}
@media screen and (max-width: 430px) {
  .techs__title {
    font-size: 18px;
    letter-spacing: -0.72px;
    padding-bottom: 27px;
  }
}

.techs__content {
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin: 90px auto 100px;
}

@media screen and (max-width: 769px) {
  .techs__content {
    margin: 80px auto 83px;
    gap: 22px;
  }
}
@media screen and (max-width: 430px) {
  .techs__content {
    gap: 24px;
    margin: 60px 0px 50px;
  }
}

.techs__subtitle {
  color: #fff;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
  margin: 0px;
  max-width: 600px;
}
@media screen and (max-width: 430px) {
  .techs__subtitle {
    font-size: 30px;
    line-height: normal;
    letter-spacing: -1.2px;
  }
}

.techs__info {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.56px;
  margin: 0px;
  max-width: 460px;
}

@media screen and (max-width: 769px) {
  .techs__info {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.48px;
  }
}
@media screen and (max-width: 430px) {
  .techs__info {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.44px;
  }
}

.techs__description {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  gap: 10px;
  margin: 0px auto;
  padding: 0px;
}
@media screen and (max-width: 746px) {
  .techs__description {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 490px) {
  .techs__description {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 325px) {
  .techs__description {
    grid-template-columns: repeat(2, 1fr);
  }
}

.techs__name {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 60px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.56px;
  text-decoration: none;
  background-color: #303030;
  transition: transform 0.3s ease;
}

.techs__name:hover {
  transform: translateY(-5px);
}

@media screen and (max-width: 793px) {
  .techs__name {
    width: 84px;
    height: 57px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
  }
}