.about-me {
    box-sizing: border-box;
    padding: 110px 70px 101px;
    background-color: #202020;
  }
  @media screen and (max-width: 769px) {
    .about-me {
      padding: 90px 50px 91px;
    }
  }

  .about-me__container {
    display: flex;
    flex-direction: column;
  }
  
  @media screen and (max-width: 430px) {
    .about-me__container {
      margin: 0px;
    }
    .about-me {
        padding: 70px 14px 68px;
      }
  }
  
  .about-me__title {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;
    text-align: left;
    margin: 0px;
    padding: 0px;
    padding-bottom: 24px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #dadada;
  }
  
  @media screen and (max-width: 430px) {
    .about-me__title {
      font-size: 18px;
      letter-spacing: -0.72px;
      padding-bottom: 28px;
    }
  }
  
  .about-me__content {
    display: flex;
    margin: 66px 0px 0px 0px;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 648px) {
    .about-me__content {
      display: grid;
      grid-template-columns: 1 1fr;
      grid-template-rows: 2 1fr;
      gap: 40px;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 430px) {
    .about-me__content {
      margin: 60px 0px 0px 0px;
    }
  }
  
  .about-me__info {
    max-width: 600px;
    text-align: left;
  }
  @media screen and (max-width: 1024px) {
    .about-me__info {
      max-width: 364px;
    }
  }
  @media screen and (max-width: 767px) {
    .about-me__info {
      grid-row: 2;
      max-width: 292px;
    }
  }
  
  .about-me__info-header {
    color: #fff;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -2px;
    margin: 0px;
    max-width: 600px;
  }
  @media screen and (max-width: 1024px) {
    .about-me__info-header {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: -1.6px;
      margin: 0px;
    }
  }

  .about-me__info-subheader {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 20px 0px 26px 0px;
  }
  @media screen and (max-width: 1024px) {
    .about-me__info-subheader {
      font-size: 12px;
      line-height: 18px;
      margin: 16px 0px 20px 0px;
    }
  }
  @media screen and (max-width: 430px) {
    .about-me__info-header {
        font-size: 30px;
        font-style: normal;
        letter-spacing: -1.2px;
      }
    .about-me__info-subheader {
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin: 16px 0px 20px 0px;
    }
    .about-me__info-description {
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        max-width: 292px;
        margin-bottom: 40px;
      }
  }
  
  .about-me__info-description {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0px 0px 100px 0px;
  }
  @media screen and (max-width: 1024px) {
    .about-me__info-description {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 85px;
    }
  }

  .about-me__info-github {
    color: #fff;
    text-decoration: none;
    opacity: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .about-me__info-github:hover {
    opacity: 0.8;
    color: gray;
    text-decoration: underline;
  }
  
  .about-me__photo {
    width: 270px;
    height: 327px;
    object-fit: cover;
    border-radius: 10px;
    border-width: 0px;
    border-style: solid;
    border-color: black;
  }
  @media screen and (max-width: 1024px) {
    .about-me__photo {
      width: 255px;
      height: 307px;
    }
  }
  @media screen and (max-width: 767px) {
    .about-me__photo {
      grid-row: 1;
      width: 292px;
      height: 352px;
      margin: 0px auto;
    }
  }
  @media screen and (max-width: 430px) {
    .about-me__photo {
      grid-row: 1;
      max-width: 430px;
      width: 100%;
    }
    .about-me__info-description {
    margin-bottom: 12px;
    }
  }