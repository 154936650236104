.more-movies {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 75px 0 0 0;
}

.more-movies__button {
  width: 320px;
  height: 36px;
  border: 1px solid #424242;
  background: transparent;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  line-height: 1.1;
  cursor: pointer;
}

.more-movies__button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .more-movies {
    margin: 58px 0 6px 0;
  }
}

@media screen and (max-width: 450px) {
  .more-movies__button {
    width: 240px;
  }
}
