.pages {
  background-color: #202020;
  min-height: 100vh;
  max-width: 1280px;
  width: 100%;
  font-family: 'Inter', 'Arial', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#root {
  max-width: 1280px;
  margin: 0 auto;
}

body {
  background-color: #202020;
  margin: 0;
  display: block;
}