.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 19px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    transition: all 0.5s linear;
  }
  
  .popup_opened {
    visibility: visible;
    opacity: 1;
  }
  
  .popup__container {
    background-color: #fff;
    max-width: 350px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    flex-direction: column;
  }
  
  .popup__error {
    outline: 2px solid #ee3465;
  }
  
  .popup__ok {
    outline: 2px solid #3ddc84;
  }
  
  .popup__message {
    color: #1b1a1a;
    margin: 20px 20px 30px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-family: 'Inter';
  }
  
  .popup__main {
    width: 80px;
    height: 80px;
    margin: 20px auto 0;
  }
  
  .popup__close-button {
    background: url(../../images/Close_Icon.svg) transparent no-repeat;
    width: 25px;
    height: 25px;
    background-size: contain;
    cursor: pointer;
    border: none;
    position: absolute;
    top: -30px;
    right: -30px;
  }
  
  .popup__close-button:hover {
    opacity: 0.8;
  }
  
  @media screen and (max-width: 450px) {
    .popup__close-button {
      right: 0;
      top: -40px;
    }
  }