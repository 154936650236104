.navTab-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-self: center;
    width: 308px;
    padding: 0px;
    margin: 0px;
    list-style-type: none;
  }
  @media screen and (max-width: 430px) {
    .navTab-list {
      margin: 0px;
      width: 258px;
      gap: 6px;
    }
  }
  
  .navTab-list__link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #303030;
    width: 96px;
    height: 36px;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
  
  .navTab-list__link:hover {
    opacity: 0.8;
  }
  
  @media screen and (max-width: 430px) {
    .navTab-list__link {
      width: 82px;
      height: 26px;
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
    }
  }