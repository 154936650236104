.header {
    display: flex;
    justify-content: space-between;
    max-width: calc(1280px - 140px);
    margin: 0 auto;
    align-items: center;
    min-height: 74px;
  }
  
  .header__link {
    cursor: pointer;
  }
  
  .header__logo {
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
  .header__logo:hover {
    opacity: 0.8;
  }
  
  .header__sign {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  
  @media screen and (max-width: 450px) {
    .header__sign {
      gap: 14px;
    }
  }
  
  .header__signup {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    text-decoration: none;
    opacity: 1;
  }
  .header__signup:hover {
    opacity: 0.8;
  }
  
  @media screen and (max-width: 450px) {
    .header__signup {
      text-align: right;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  
  .header__signin-button {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    background-color: #3ddc84;
    height: 32px;
    width: 76px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    opacity: 1;
  }
  .header__signin-button:hover {
    opacity: 0.8;
  }
  
  @media screen and (max-width: 450px) {
    .header__signin-button {
      margin-left: 0px;
      width: 54px;
      height: 26px;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  
  .header__signup:hover {
    opacity: 0.8;
  }
  
  .header__signin-button:hover {
    opacity: 0.7;
  }
  
  .header__burger-button {
    display: none;
  }
  
  @media screen and (max-width: 1180px) {
    .header {
      max-width: calc(100% - 80px);
    }
  }
  
  @media screen and (max-width: 768px) {
    .header {
      max-width: calc(100% - 60px);
    }
  
    .header__burger-button {
      width: 35px;
      height: 23px;
      position: relative;
      display: flex;
      z-index: 2;
      cursor: pointer;
      background-size: contain;
      background: url(../../images/burger-open.svg) transparent no-repeat;
      border: none;
    }
  
    .header__navigation {
      display: none;
    }
  
    .header__profile {
      display: none;
    }
  }
  
  @media screen and (max-width: 450px) {
    .header {
      max-width: calc(100% - 28px);
      margin: 0 14px;
    }
  }
