.navigation {
    display: flex;
    margin: 28px 0 28px 60px;
  }
  
  .navigation__link {
    color: #fff;
    font-size: 13px;
    line-height: 18px;
    margin-right: 16px;
    text-decoration: none;
    font-weight: 400;
  }
  
  .navigation__link_hidden {
    visibility: hidden;
  }
  
  .navigation__link:last-child {
    margin-right: 0;
  }
  
  .navigation__link:hover {
    opacity: 0.7;
  }
  
  .navigation__link_active {
    font-weight: 500;
  }
  
  @media screen and (max-width: 768px) {
    .navigation {
      flex-direction: column;
      text-align: center;
      margin: 76px 0 0 0;
    }
  
    .navigation__link {
      font-size: 18px;
      line-height: 22px;
      margin: 28px 0 0 0;
      align-self: center;
    }
    .navigation__link_active {
      font-weight: 400;
      position: relative;
    }
  
    .navigation__link_active::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: -5px;
      height: 2px;
      width: 100%;
      background-color: #fff;
    }
  
    .navigation__link_hidden {
      visibility: visible;
    }
  }