.promo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    background-color: #272727;
    margin: 20px 40px 0px;
    border-radius: 10px;
    background-position: right -115px top -20px, left -100px bottom -10px;
    background-size: 250px 250px, 250px 250px;
    background-repeat: no-repeat;
  }
  
  .promo__container {
    display: flex;
    flex-direction: column;
    margin: 160px 0px 30px;
    max-width: 100%;
    gap: 151px;
  }
  
  .promo__title {
    color: #fff;
    max-width: 730px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    letter-spacing: -2px;
    font-size: 50px;
    font-weight: normal;
    line-height: 58px;
    z-index: 1;
  }
  
  @media screen and (max-width: 769px) {
    .promo {
        margin: 20px 20px 0px;
      }
    .promo__container {
      margin: 350px 24px 30px;
      gap: 314px;
    }
    .promo__title {
      font-size: 40px;
      font-weight: 400;
      line-height: 52px;
      letter-spacing: -1.6px;
    }
  }
  
  @media screen and (max-width: 430px) {
    .promo {
        margin: 14px 14px 0px;
        background-position: right -110px top -20px, left -80px bottom -5px;
        background-size: 200px 200px, 200px 200px;
      }
    .promo__container {
      margin: 220px 0px 17px;
      gap: 212px;
    }
    .promo__title {
      font-size: 28px;
      font-weight: 400;
      line-height: 37px;
      letter-spacing: -1.16px;
    }
  }
  